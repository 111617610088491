"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import ErrorPage from "~/shared/Error";

export default function Error({
  error,
}: {
  error: { digest?: string } & Error;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorPage />
      </body>
    </html>
  );
}
